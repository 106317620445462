import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import BottomTab from './BottomTab';
import axios from 'axios';
import Api from "../Api/Api.json";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryBar,
} from 'victory';
import '../styles/VisualizeWellnessData.css'; 
import { formatDate } from '../utility/DataTransformUtils';

var userid=0;

const handleCardClick = (page) => {
  console.log('card clicked');
};

const VisualizeWellnessData = () => {
    const [stepCountList,setStepCountList]=useState([{}]);
    const [caloriesList,setCaloriesList]=useState([{}]);
    const [distanceList,setDistanceList]=useState([{}]);
    const [dayNameList,setDayNameList]=useState([]);
    useEffect(()=>{ 
      if (localStorage.getItem('patient_id')!==null){
        //navigate('/Landing/tab1')
        userid=JSON.parse(localStorage.getItem('patient_id'));
        console.log(userid);
      }

          const headers = { headers: { "Content-Type": "application/json" } }
          const url = Api.FITNESS_API;
          const requestData = {
            task: 'getLast7DaysTotalActivityData',
            id: userid,
            timezone:'Asia/Dhaka',
          };
          console.log('calling page data api');
          axios.post(url,requestData,headers)
          .then(result=>{
          if(result.data){
            console.log(JSON.stringify(result.data));
            setStepCountList(result.data.stepCountList);
            setDistanceList(result.data.distanceList);
            setCaloriesList(result.data.caloriesList);
            /*
            setStepCountList(result.data.hrvList);
            setDistanceList(result.data.hrvList);
            setCaloriesList(result.data.hrvList);
            setDayNameList(result.data.dayNameList);
            */
          }  
        });
    },
    []);
    

    return (
        <div className='scroll-container'>
        <div className="content">
        
        <h4>Calories (Cal):</h4>
          <VictoryChart
      domain={{ y: [0, 600] }}
      height={400}
      padding={{ top: 20, bottom: 60, left: 60, right: 60 }}
    >
      <defs>
        <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#1FD2DC" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#1FD2DC" stopOpacity="0.2" />
        </linearGradient>
      </defs>

      <VictoryAxis
        tickValues={[1,2,3,4,5,6,7]}//
        tickFormat={dayNameList}//['Sat','Sun','Mon','Tue','Wed','Thu','Fri']}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'none' },
        }}
      />
      
      <VictoryAxis
        dependentAxis
        tickValues={[0, 200, 400, 600]}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'grey', strokeWidth: 0.5 },
        }}
      />

      <VictoryBar
        data={caloriesList}
        size={4}
        style={{
          data: { fill: '#1FD2DC' },
        }}
      />
        <VictoryLine
        data={caloriesList}
        interpolation="natural"
        size={4}
        style={{ data: { stroke: "#c43a31", strokeWidth: 7, strokeLinecap: "round" } }}
      />
      </VictoryChart>

      <h4>Distance (Meters):</h4>
          <VictoryChart
      domain={{ y: [0, 2000] }}
      height={400}
      padding={{ top: 20, bottom: 60, left: 60, right: 60 }}
    >
      <defs>
        <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#1FD2DC" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#1FD2DC" stopOpacity="0.2" />
        </linearGradient>
      </defs>

      <VictoryAxis
        tickValues={[1,2,3,4,5,6,7]}//
        tickFormat={dayNameList}//['Sat','Sun','Mon','Tue','Wed','Thu','Fri']}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'none' },
        }}
      />
      
      <VictoryAxis
        dependentAxis
        tickValues={[0, 500, 1000, 1500, 2000]}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'grey', strokeWidth: 0.5 },
        }}
      />

      <VictoryBar
        data={distanceList}
        size={4}
        style={{
          data: { fill: '#1FD2DC' },
        }}
      />
        <VictoryLine
        data={distanceList}
        interpolation="natural"
        size={4}
        style={{ data: { stroke: "#c43a31", strokeWidth: 7, strokeLinecap: "round" } }}
      />
      </VictoryChart>

        <BottomTab />
        </div>
        </div>
    );
};

export default VisualizeWellnessData;