import React,{useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,Link,useLocation, Outlet,useNavigate } from 'react-router-dom';
import Tab1 from './Tab1';
import BottomTab from './BottomTab';
import '../styles/LandingPage.css'
import axios from 'axios';
import Api from "../Api/Api.json";

const mockApiData = [
    { id: 1, name: 'John Doe', age: 25, phone: '123-456-7890' },
    { id: 2, name: 'Jane Smith', age: 30, phone: '234-567-8901' },
    { id: 3, name: 'Sam Wilson', age: 22, phone: '345-678-9012' },
    // Add more rows as needed
  ];

const RPMList = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15;
  const location = useLocation();
  const navigate=useNavigate();
  useEffect(() => {
    // You can replace mockApiData with an actual API call
    // fetch('/api/endpoint').then(response => response.json()).then(setData);
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    const requestData = {
      task: 'getRPMPatientList',
    };
    console.log('calling page data api');
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
    setData(result.data);
    console.log(JSON.stringify(result.data));
    }
    })

    //setData(mockApiData);
  }, []);

  const handleClick = async (item) => {
    console.log(item.patient_id); 
    await localStorage.setItem("patient_id",JSON.stringify(item.patient_id));
    await localStorage.setItem("patient_phone",JSON.stringify(item.phone));
    navigate(`/landing/tab2`)
   };
  //pagination related code
    // Calculate the total number of pages
    const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

    // Get the items for the current page
    const currentItems = data.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );
  
    // Function to handle page changes
    const handlePageClick = (page) => {
      setCurrentPage(page);
    };

  return (
    <div className="landing-container">
      {/*
<div className="paginated-list-container">
      <div className="scrollable-list">
        <ul>
          {currentItems.map((item) => (
            <li
              key={item.id}
              style={{
                cursor: "pointer",
                marginBottom: "10px",
                listStyle: "none",
              }}
              onClick={() => handleClick(item)}
            >
              {item.name} | {item.age} | {item.phone} | {item.prescription_date}
            </li>
          ))}
        </ul>
      </div>


      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageClick(index + 1)}
            style={{
              margin: "0 5px",
              padding: "5px 10px",
              backgroundColor: currentPage === index + 1 ? "#007bff" : "#f8f9fa",
              color: currentPage === index + 1 ? "#fff" : "#000",
              border: "1px solid #ccc",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
    */}

<div className="content">
    {/* Main content of each tab */}
    <p>Dashboard</p>
    <h2>List of Semion Patients:</h2>
    <ul style={{
      maxHeight: 'calc(100vh - 100px)',  // Assuming BottomTab height is 100px, adjust as necessary
      overflowY: 'auto',
      paddingBottom: '100px'  // This creates space for the bottom tab
    }}>
      {data.map((item) => (
        <li
          key={item.id}
          style={{ 
            cursor: 'pointer', 
            marginBottom: '10px', 
            listStyle: 'none' 
          }}
          onClick={() => handleClick(item)}
        >
          {item.name} | {item.age} | {item.phone} | {item.prescription_date}
        </li>
      ))}
    </ul>
  </div>

  <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
    <BottomTab />
  </div>

    </div>
  );
};

export default RPMList;
