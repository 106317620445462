import React, { useState, useEffect } from "react";
import axios from "axios";
import Api from "../Api/Api.json";

var userid=0;
const RiskFactorFormVisualization = () => {
  const [dates, setDates] = useState([]); // List of dates
  const [selectedDate, setSelectedDate] = useState(null); // Selected date
  const [formData, setFormData] = useState(null); // Data for the selected date
  const [loading, setLoading] = useState(false); // Loading state

  // Fetch dates when the component mounts
  useEffect(() => {

    if (localStorage.getItem('patient_id')!==null){

        userid=JSON.parse(localStorage.getItem('patient_id'));
        console.log(userid);
      }
    const fetchDates = async () => {
      try {
        const requestData =
        {
          "task": "getPatientRiskFactorDataDateList",
          "patient_id": userid
        };
        const headers = { headers: { "Content-Type": "application/json" } }
        const url = Api.PREGNANCY_DATA_API;
        axios.post(url,requestData,headers)
        .then(response=>{
        if(response.data){
        console.log(response.data);
        setDates(response.data);
        }
        })
      } catch (error) {
        console.error("Error fetching dates:", error);
      }
    };
    fetchDates();
  }, []);

  // Fetch formdata when a date is clicked
  const fetchFormData = async (date) => {
    setLoading(true);
    setSelectedDate(date);
    try {
        const requestData =
        {
          "task": "getRiskFactorDataFormOnDate",
          "patient_id": userid,
          "creation_date": selectedDate
        };
        console.log(requestData);
        const headers = { headers: { "Content-Type": "application/json" } }
        const url = Api.PREGNANCY_DATA_API;
        axios.post(url,requestData,headers)
        .then(response=>{
        if(response.data){
        console.log(response.data);
        setFormData(response.data[0]);
        }
        })


    } catch (error) {
      console.error("Error fetching form data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
          {/* Date List */}
          <div style={styles.dateList}>
        {dates && dates.map((date, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <button
            key={index}
            style={{
              ...styles.dateButton,
              ...(selectedDate === date ? styles.selectedDateButton : {}),
            }}
            onClick={() => fetchFormData(date)}
          >
            {date}
          </button>
          </div>

        ))}
      </div>


    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px", fontFamily: "Arial, sans-serif" }}>
    {loading ? (
          <p>Loading...</p>
        ) : formData ? (
            <div>
      <h2>Patient Data</h2>
      <p>
        <strong>Patient ID:</strong> {formData.patient_id}
      </p>
      <p>
        <strong>Creation Date:</strong> {formData.creation_date}
      </p>
      <hr />
      
      {Object.keys(formData)
        .filter((key) => !["id", "patient_id", "creation_date"].includes(key)) // Exclude metadata keys
        .map((sectionKey) => {
          const sectionData = JSON.parse(formData[sectionKey]); // Parse JSON string for each section
          return (
            <div key={sectionKey} style={{ marginBottom: "30px" }}>
              <h3>{sectionData.header}</h3>
              {Object.keys(sectionData)
                .filter((key) => key.startsWith("q")) // Filter for questions (q1, q2, etc.)
                .map((questionKey, index) => (
                  <div key={questionKey} style={{ marginBottom: "10px" }}>
                    <p>
                      <strong>{index + 1}. {sectionData[questionKey]}</strong>
                    </p>
                    <p>
                      <em>Answer:</em> {sectionData[`a${index + 1}`] || "Not answered"}
                    </p>
                  </div>
                ))}
              <hr />
            </div>
          );
        })}
      </div> ) : (
          <p>Select a date to view details.</p>
        )}
    </div>
    </div>
  );
};

// Styles
const styles = {
    container: {
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      background: "#000", // Black background
      color: "#fff", // White text for contrast
      minHeight: "100vh",
    },
    dateList: {
        display: "block",
        marginBottom: "20px",
    },
    dateButton: {
      padding: "10px 15px",
      background: "#1e90ff", // Bright blue
      color: "#fff", // White text
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background 0.3s",
    },
    selectedDateButton: {
      background: "#4682b4", // Slightly darker blue for selected
    },
    formDataContainer: {
      background: "#222", // Dark gray background
      padding: "20px",
      borderRadius: "5px",
      boxShadow: "0 4px 6px rgba(255, 255, 255, 0.1)", // Light shadow for visibility
      color: "#fff", // White text
    },
    dataDisplay: {
      background: "#333", // Slightly lighter gray for text container
      padding: "10px",
      borderRadius: "5px",
      overflowX: "auto",
      color: "#00ff00", // Green text for better visibility
      fontFamily: "Courier New, monospace",
    },
    loadingText: {
      color: "#00ff00", // Green loading text
    },
    heading: {
      color: "#ffa500", // Orange for headings
    },
    infoText: {
      color: "#aaa", // Light gray for default info text
    },
  };
  

export default RiskFactorFormVisualization;
