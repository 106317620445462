import React, { useState,useEffect } from "react";
import axios from "axios";
import Api from "../Api/Api.json";

var userid=0;
const moment = require('moment');

function RiskFactorFormIntake() {
  const [formData, setFormData] = useState({
    anemia: {
      header:"রক্তশূন্যতা (Anemia)",
      q1: "আপনি কি অস্বাভাবিকভাবে ক্লান্ত বা দুর্বল বোধ করছেন, এমনকি বিশ্রামের পরেও?",
      q2: "সাধারণ কাজ করার সময় কি আপনার শ্বাস নিতে কষ্ট হয়?",
      q3: "আপনি কি সম্প্রতি ত্বক, মাড়ি বা নখ ফ্যাকাসে হয়ে গেছে বলে মনে করছেন?",
      q4: "আপনি কি মাথা ঘোরা, মাথাব্যথা বা হৃদস্পন্দন বেড়ে যাওয়ার অভিজ্ঞতা পেয়েছেন?",
      a1: "",
      a2: "",
      a3: "",
      a4: "",
    },
    hypertension: {
      header:"উচ্চ রক্তচাপজনিত ব্যাধি (Hypertensive Disorders)",
      q1: "আপনি কি মারাত্মক বা স্থায়ী মাথাব্যথা অনুভব করেছেন?",
      q2: "আপনি কি আপনার হাত, মুখ, বা পায়ে স্থায়ী ফোলাভাব লক্ষ্য করেছেন?",
      q3: "আপনি কি ঝাপসা দৃষ্টি, আলোতে সংবেদনশীলতা, বা চোখে দাগ দেখতে পাচ্ছেন?",
      q4: "আপনি কি আপনার পেটের উপরিভাগে বা পাঁজরের নিচে ব্যথা অনুভব করেছেন?",
      a1: "",
      a2: "",
      a3: "",
      a4: "",
    },
    diabetes:{
        header:"ডায়াবেটিস সম্পর্কিত জটিলতা (Diabetes-related Complications)",
        q1: "আপনি কি অতিরিক্ত পিপাসা বা বারবার প্রস্রাব করার অভিজ্ঞতা পেয়েছেন?",
        q2: "আপনি কি খাবারের পরে অস্বাভাবিকভাবে ক্লান্ত বা অলস বোধ করছেন?",
        q3: "আপনি কি সম্প্রতি উল্লেখযোগ্য ওজন বেড়েছে বা হঠাৎ ওজন পরিবর্তন লক্ষ্য করেছেন?",
        q4: "আপনি কি আগে গর্ভকালীন ডায়াবেটিসের অভিজ্ঞতা পেয়েছেন বা একটি বড় শিশুর জন্ম দিয়েছেন?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",
    },
    cardiac_disease:{
        header:"হৃদরোগ (Cardiac Disease)",
        q1: "আপনি কি বুকে ব্যথা, চাপ, বা হৃদস্পন্দন অনুভব করছেন?",
        q2: "আপনি কি হালকা কাজে বা শোয়া অবস্থায় শ্বাসকষ্ট বোধ করেন?",
        q3: "আপনি কি মাথা ঘোরা বা অজ্ঞান হওয়ার অভিজ্ঞতা পেয়েছেন?",
        q4: "আপনার কি হৃদরোগ বা পারিবারিক ইতিহাসে হৃদরোগ ছিল?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    preeclampsia:{
        header:"প্রিইক্লাম্পসিয়া (Preeclampsia)",
        q1: "আপনি কি আপনার হাত, মুখ, বা পায়ে হঠাৎ ফোলাভাব অনুভব করেছেন?",
        q2: "আপনি কি আগে গর্ভধারণে উচ্চ রক্তচাপের অভিজ্ঞতা পেয়েছেন?",
        q3: "আপনি কি এমন মাথাব্যথা অনুভব করছেন যা সাধারণ ওষুধে দূর হয় না?",
        q4: "আপনি কি প্রস্রাব কম বা প্রস্রাবে পরিবর্তন লক্ষ্য করেছেন?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    uti:{
        header:"মূত্রনালীর সংক্রমণ (UTI)",
        q1: "প্রস্রাব করার সময় কি জ্বালা বা ব্যথা অনুভব করেন?",
        q2: "আপনি কি প্রস্রাবের ফ্রিকোয়েন্সি বা তাড়াহুড়ো বাড়তে দেখেছেন?",
        q3: "আপনার কি তলপেট বা পিঠে ব্যথা হচ্ছে?",
        q4: "আপনি কি ঘোলাটে, দুর্গন্ধযুক্ত বা রক্তমিশ্রিত প্রস্রাব লক্ষ্য করেছেন?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    liver_disease:{
        header: "লিভারের রোগ (Liver Disease)",
        q1: "আপনি কি বিশেষত হাত ও পায়ে তীব্র চুলকানি অনুভব করছেন?",
        q2: "আপনি কি গাঢ় প্রস্রাব বা ফ্যাকাসে মল লক্ষ্য করেছেন?",
        q3: "আপনি কি ক্রমাগত বমি বমি ভাব বা ক্ষুধামন্দা অনুভব করছেন?",
        q4: "আপনার কি পাঁজরের নিচে ডানদিকে ব্যথা হচ্ছে?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    coagulation_disorder:{
        header: "রক্ত জমাট বাঁধার ব্যাধি (Coagulation Disorder)",
        q1: "আপনার কি কোনো অস্বাভাবিক বা অতিরিক্ত রক্তক্ষরণ হয়েছে (যেমন মাড়ি বা নাক)?",
        q2: "আপনি কি শরীরে আঘাত ছাড়াই আঘাতের দাগ লক্ষ্য করেছেন?",
        q3: "আপনি কি রক্ত জমাট বাঁধার সমস্যা বা রক্ত জমাট বাঁধার অভিজ্ঞতা পেয়েছেন?",
        q4: "আপনার পরিবারের কি রক্ত জমাট বাঁধার ইতিহাস আছে?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    placenta_previa:{
        header: "প্লাসেন্টা প্রিভিয়ার (Placenta Previa)",
        q1: "আপনি কি এই গর্ভাবস্থায় যোনি থেকে রক্তপাত লক্ষ্য করেছেন?",
        q2: "আপনি কি আগের গর্ভধারণে প্লাসেন্টা প্রিভিয়ার অভিজ্ঞতা পেয়েছেন?",
        q3: "আলট্রাসাউন্ডে কি প্লাসেন্টা নীচে থাকার কথা বলা হয়েছে?",
        q4: "আপনার কি পেলভিক অঞ্চলে চাপ বা অস্বস্তি অনুভব করছেন?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    thyroid_disorder:{
        header: "থাইরোইড ব্যাধি (Thyroid Disorder)",
        q1: "আপনি কি অস্বাভাবিক ক্লান্তি বা অবসন্নতা অনুভব করছেন?",
        q2: "আপনার কি ওজন বেড়ে বা কমেছে, এবং এটি ব্যাখ্যাতীত?",
        q3: "আপনি কি অন্যদের তুলনায় অস্বাভাবিকভাবে ঠান্ডা বা গরম বোধ করছেন?",
        q4: "আপনি কি চুল পড়া, শুষ্ক ত্বক বা হৃদস্পন্দনের পরিবর্তন লক্ষ্য করেছেন?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    antepartum_hemorrhage:{
        header: "গর্ভকালীন রক্তক্ষরণ (Antepartum Hemorrhage)",
        q1: "আপনি কি এই গর্ভাবস্থায় যোনি থেকে রক্তপাত অনুভব করেছেন?",
        q2: "রক্তপাতের সময় কি আপনার পেটে ব্যথা বা ক্র্যাম্প হয়?",
        q3: "আপনার কি সম্প্রতি পেটের কোনো আঘাত বা চোট হয়েছে?",
        q4: "আগের গর্ভাবস্থায় কি রক্তপাতের সমস্যা ছিল?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
    amniotic_fluid:{
        header: "আমনিওটিক ফ্লুইড (Amniotic Fluid)",
        q1: "আপনি কি যোনি থেকে কোনো তরল লিক হচ্ছে বলে মনে করছেন?",
        q2: "আলট্রাসাউন্ডে কি অ্যামনিয়োটিক ফ্লুইডের স্তর অস্বাভাবিক বলা হয়েছে?",
        q3: "আপনি কি আপনার শিশুর অস্বাভাবিকভাবে বড় বা ছোট নড়াচড়া অনুভব করছেন?",
        q4: "আপনার কি আগের গর্ভাবস্থায় অল্প বা অতিরিক্ত অ্যামনিয়োটিক ফ্লুইডের অভিজ্ঞতা ছিল?",
        a1: "",
        a2: "",
        a3: "",
        a4: "",        
    },
  });

  useEffect(()=>{ 
    if (localStorage.getItem('patient_id')!==null){

      userid=JSON.parse(localStorage.getItem('patient_id'));
      console.log(userid);
    }
  },[])

  const handleChange = (section, answerKey, value) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [answerKey]: value,
      },
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("Form Data Submitted: ", formData);
    const requestData =
    {
      "task": "submitRiskFactorDataForm",
      "query_dict": formData,
      "patient_id": userid,
      "creation_time":moment().format('YYYY-MM-DD HH:mm:ss')
}
console.log(requestData);

const headers = { headers: { "Content-Type": "application/json" } }
const url = Api.PREGNANCY_DATA_API;
axios.post(url,requestData,headers)
.then(result=>{
if(result.data){
console.log(JSON.stringify(result.data));
//console.log("Data submitted.");
}
})

  };

  return (
        <form onSubmit={handleSubmit} style={{ maxWidth: "600px", margin: "auto" }}>
          <h2>ঝুঁকি ফ্যাক্টর</h2>
    
          {Object.keys(formData).map((sectionKey) => {
            const section = formData[sectionKey];
            return (
              <div key={sectionKey} style={{ marginBottom: "30px" }}>
                {/* Section Header */}
                <h3>{section.header}</h3>
            {/* Questions */}
            {Object.keys(section)
              .filter((key) => key.startsWith("q")) // Only process question keys
              .map((questionKey, index) => (
                <div key={`${sectionKey}-${questionKey}`} style={{ marginBottom: "15px" }}>
                  <label>
                    {index + 1}. {section[questionKey]}
                  </label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name={`${sectionKey}-${questionKey}`}
                        value="হ্যা"
                        checked={section[`a${index + 1}`] === "হ্যা"}
                        onChange={(e) =>
                          handleChange(sectionKey, `a${index + 1}`, e.target.value)
                        }
                      />
                      হ্যা
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`${sectionKey}-${questionKey}`}
                        value="না"
                        checked={section[`a${index + 1}`] === "না"}
                        onChange={(e) =>
                          handleChange(sectionKey, `a${index + 1}`, e.target.value)
                        }
                      />
                      না
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`${sectionKey}-${questionKey}`}
                        value="জানি না"
                        checked={section[`a${index + 1}`] === "জানি না"}
                        onChange={(e) =>
                          handleChange(sectionKey, `a${index + 1}`, e.target.value)
                        }
                      />
                      জানি না
                    </label>
                  </div>
                </div>
              ))}
          </div>
        );
      })}

      {/* Submit Button */}
      <button type="submit" style={{ padding: "10px 20px", marginTop: "20px" }}>
        জমা দিন
      </button>
    </form>
  );
}

export default RiskFactorFormIntake;
