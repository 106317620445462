import React, { useState,formRef,useEffect} from "react";
import axios from "axios";
import Api from "../Api/Api.json";

var userid=0;
const moment = require('moment');

const AntenatalCardFormIntake = () => {

  const [formData, setFormData] = useState({
    patient_info: {
      label: "Patient Information",
      patient_name: "",
      patient_age: "",
      patient_occupation: "",
      patient_address: "",
      patient_cell_no: "",
      patient_blood_group: "",
      registration_number: "",
    },
    menstrual_history: {
      label: "Menstrual History",
      mp: "",
      mc: "",
      lmp: "",
      edd: "",
    },
    husband_info: {
      label: "Husband Information",
      husband_name: "",
      husband_age: "",
      husband_occupation: "",
      husband_address: "",
      husband_cell_no: "",
      husband_blood_group: "",
    },
    obstetric_history: {
      label: "Obstetric History",
      married_for: "",
      parity: "",
      gravida: "",
      abortion_mr: "",
      alc: "",
    },
    personal_history: {
      label: "Personal History",
      blood_transfusion_husband: false,
      blood_transfusion_wife: false,
      alcohol_or_cigarette_use_husband: false,
      alcohol_or_cigarette_use_wife: false,
      living_abroad_husband: false,
      living_abroad_wife: false,
      history_of_std_husband: false,
      history_of_std_wife: false,
      exposure_history_husband: false,
      exposure_history_wife: false,
    },
    immunization_history: {
      label: "Immunization History",
      tetanus: "",
      hepatitis_B: "",
      rubella: "",
      others: "",
    },
    investigations: {
      label: "Investigations",
      CBC: "",
      Blood_Glucose_after_2_hours: "",
      TSH: "",
      FT4: "",
      urine_rme_cs: "",
      VDRL: "",
      HbsAg_anti_hcv: "",
      HIV: "",
      Ultrasonogram:"",
    },
    antenatal_examination: {
      label: "Antenatal Examination",
      Date: "",
      Gestation_weeks: "",
      weight: "",
      pulse: "",
      blood_pressure: "",
      anemia: "",
      Edema: "",
      SFH_cm: "",
      Lie_presentation: "",
      Liquor_volume: "",
      Fetal_movement: "",
      Fetal_position: "",
      Fetal_heart_rate: "",
      Urine_protein: "",
      Urine_glucose: "",
    },
    postnatal_info: {
      label: "Postnatal Information",
      delivery_date: "",
      babyStatus: "",
      Place_of_delivery: "",
      baby_weight: "",
      Mode_of_delivery: "",
      Post_Partum_Complications: "",
    },
  });

  
  useEffect(()=>{ 
    if (localStorage.getItem('patient_id')!==null){

      userid=JSON.parse(localStorage.getItem('patient_id'));
      console.log(userid);
    }
  },[])

//  const formRef = useRef(null);

const handleChange = (event) => {
  const { name, type, checked, value } = event.target;
  const [section, field] = name.split(".");
  /*
  console.log("Section:", section);
  console.log("Field:", field);
  console.log("Type:", type);
  console.log("Value:", type === "checkbox" ? checked : value);
  */
  setFormData((prevFormData) => ({
    ...prevFormData,
    [section]: {
      ...prevFormData[section],
      [field]: type === "checkbox" ? checked : value, // Use `checked` for checkboxes
    },
  }));
};

  

  const handleSubmit = (e) => {
    e.preventDefault();

    //const formData = new FormData(formRef.current);
    const formValues = formData;
  
    const requestData =
        {
          "task": "submitANCDataForm",
          "query_dict": formData,
          "patient_id": userid,
          "creation_date":moment().format('YYYY-MM-DD HH:mm:ss')
  }
    console.log(requestData);

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PREGNANCY_DATA_API;
    
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
    console.log(JSON.stringify(result.data));
    console.log("Data submitted.");
    }
    })
    
};
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h1>Antenatal Card Form</h1>
      <form onSubmit={handleSubmit}>
        {/* Patient Information Section */}
        <h2>Patient Information</h2>
        <label>
          Patient Name:
          <input type="text" name="patient_info.patient_name" value={formData.patient_info.patient_name} onChange={handleChange} />
        </label>
        <label>
          Age:
          <input type="number" name="patient_info.patient_age" value={formData.patient_info.patient_age} onChange={handleChange} />
        </label>
        <label>
          Occupation:
          <input type="text" name="patient_info.patient_occupation" value={formData.patient_info.patient_occupation} onChange={handleChange} />
        </label>
        <label>
          Address:
          <textarea name="patient_info.patient_address" value={formData.patient_info.patient_address} onChange={handleChange} />
        </label>
        <label>
          Cell No:
          <input type="text" name="patient_info.patient_cell_no" value={formData.patient_info.patient_cell_no} onChange={handleChange} />
        </label>
        <label>
          Blood Group:
          <input type="text" name="patient_info.patient_blood_group" value={formData.patient_info.patient_blood_group} onChange={handleChange} />
        </label>

        {/* Menstrual History Section */}
        <h2>Menstrual History</h2>
        <label>
          MP (Menstrual Period):
          <input type="text" name="menstrual_history.mp" value={formData.menstrual_history.mp} onChange={handleChange} />
        </label>
        <label>
          MC (Menstrual Cycle):
          <input type="text" name="menstrual_history.mc" value={formData.menstrual_history.mc} onChange={handleChange} />
        </label>
        <label>
          LMP (Last Menstrual Period):
          <input type="text" name="menstrual_history.lmp" value={formData.menstrual_history.lmp} onChange={handleChange} />
        </label>
        <label>
          EDD (Expected Date of Delivery):
          <input type="text" name="menstrual_history.edd" value={formData.menstrual_history.edd} onChange={handleChange} />
        </label>

        {/* Husband's Information Section */}
        <h2>Husband's Information</h2>
        <label>
          Husband Name:
          <input type="text" name="husband_info.husband_name" value={formData.husband_info.husband_name} onChange={handleChange} />
        </label>
        <label>
          Age:
          <input type="number" name="husband_info.husband_age" value={formData.husband_info.husband_age} onChange={handleChange} />
        </label>
        <label>
          Occupation:
          <input type="text" name="husband_info.husband_occupation" value={formData.husband_info.husband_occupation} onChange={handleChange} />
        </label>
        <label>
          Cell No:
          <input type="text" name="husband_info.husband_cell_no" value={formData.husband_info.husband_cell_no} onChange={handleChange} />
        </label>
        <label>
          Blood Group:
          <input type="text" name="husband_info.husband_blood_group" value={formData.husband_info.husband_blood_group} onChange={handleChange} />
        </label>

        <h2>Obstetric History</h2>
<label>
  Married For (Years):
  <input
    type="number"
    name="obstetric_history.married_for"
    value={formData.obstetric_history.married_for}
    onChange={handleChange}
  />
</label>
<label>
  Parity:
  <input
    type="number"
    name="obstetric_history.parity"
    value={formData.obstetric_history.parity}
    onChange={handleChange}
  />
</label>
<label>
  Gravida:
  <input
    type="number"
    name="obstetric_history.gravida"
    value={formData.obstetric_history.gravida}
    onChange={handleChange}
  />
</label>
<label>
  Abortion/MR:
  <input
    type="number"
    name="obstetric_history.abortion_mr"
    value={formData.obstetric_history.abortion_mr}
    onChange={handleChange}
  />
</label>
<label>
  ALC (Any Living Children):
  <input
    type="number"
    name="obstetric_history.alc"
    value={formData.obstetric_history.alc}
    onChange={handleChange}
  />
</label>
<h2>Personal History</h2>
<label>
  Blood Transfusion (Husband):
  <input
    type="checkbox"
    name="personal_history.blood_transfusion_husband"
    checked={formData.personal_history.blood_transfusion_husband}
    onChange={handleChange}
  />
</label>
<label>
  Blood Transfusion (Wife):
  <input
    type="checkbox"
    name="personal_history.blood_transfusion_wife"
    checked={formData.personal_history.blood_transfusion_wife}
    onChange={handleChange}
  />
</label>
<label>
  Alcohol/Cigarette Use (Husband):
  <input
    type="checkbox"
    name="personal_history.alcohol_or_cigarette_use_husband"
    checked={formData.personal_history.alcohol_or_cigarette_use_husband}
    onChange={handleChange}
  />
</label>
<label>
  Alcohol/Cigarette Use (Wife):
  <input
    type="checkbox"
    name="personal_history.alcohol_or_cigarette_use_wife"
    checked={formData.personal_history.alcohol_or_cigarette_use_wife}
    onChange={handleChange}
  />
</label>
<label>
  Living Abroad (Husband):
  <input
    type="checkbox"
    name="personal_history.living_abroad_husband"
    checked={formData.personal_history.living_abroad_husband}
    onChange={handleChange}
  />
</label>
<label>
  Living Abroad (Wife):
  <input
    type="checkbox"
    name="personal_history.living_abroad_wife"
    checked={formData.personal_history.living_abroad_wife}
    onChange={handleChange}
  />
</label>
<label>
  History of STDs (Husband):
  <input
    type="checkbox"
    name="personal_history.history_of_std_husband"
    checked={formData.personal_history.history_of_std_husband}
    onChange={handleChange}
  />
</label>
<label>
  History of STDs (Wife):
  <input
    type="checkbox"
    name="personal_history.history_of_std_wife"
    checked={formData.personal_history.history_of_std_wife}
    onChange={handleChange}
  />
</label>
<label>
  Exposure History (Husband):
  <input
    type="checkbox"
    name="personal_history.exposure_history_husband"
    checked={formData.personal_history.exposure_history_husband}
    onChange={handleChange}
  />
</label>
<label>
  Exposure History (Wife):
  <input
    type="checkbox"
    name="personal_history.exposure_history_wife"
    checked={formData.personal_history.exposure_history_wife}
    onChange={handleChange}
  />
</label>
<h2>Immunization History</h2>
<label>
  Tetanus (Doses):
  <input
    type="text"
    name="immunization_history.tetanus"
    value={formData.immunization_history.tetanus}
    onChange={handleChange}
  />
</label>
<label>
  Hepatitis B (Doses):
  <input
    type="text"
    name="immunization_history.hepatitis_B"
    value={formData.immunization_history.hepatitis_B}
    onChange={handleChange}
  />
</label>
<label>
  Rubella:
  <input
    type="text"
    name="immunization_history.rubella"
    value={formData.immunization_history.rubella}
    onChange={handleChange}
  />
</label>
<label>
  Others:
  <textarea
    name="immunization_history.others"
    value={formData.immunization_history.others}
    onChange={handleChange}
  />
</label>
{/*
<h2>Findings of First Examination</h2>
<label>
  Pulse:
  <input
    type="text"
    name="pulse"
    value={formData.pulse}
    onChange={handleChange}
  />
</label>
<label>
  Blood Pressure (BP):
  <input
    type="text"
    name="bp"
    value={formData.bp}
    onChange={handleChange}
  />
</label>
<label>
  Weight:
  <input
    type="text"
    name="weight"
    value={formData.weight}
    onChange={handleChange}
  />
</label>
<label>
  Anemia:
  <input
    type="text"
    name="anemia"
    value={formData.anemia}
    onChange={handleChange}
  />
</label>
<label>
  Jaundice:
  <input
    type="text"
    name="jaundice"
    value={formData.jaundice}
    onChange={handleChange}
  />
</label>
<label>
  Edema:
  <input
    type="text"
    name="edema"
    value={formData.edema}
    onChange={handleChange}
  />
</label>
<label>
  Heart:
  <input
    type="text"
    name="heart"
    value={formData.heart}
    onChange={handleChange}
  />
</label>
<label>
  Lungs:
  <input
    type="text"
    name="lungs"
    value={formData.lungs}
    onChange={handleChange}
  />
</label>
<label>
  Spleen:
  <input
    type="text"
    name="spleen"
    value={formData.spleen}
    onChange={handleChange}
  />
</label>
<label>
  Thyroid:
  <input
    type="text"
    name="thyroid"
    value={formData.thyroid}
    onChange={handleChange}
  />
</label>
<label>
  Teeth:
  <input
    type="text"
    name="teeth"
    value={formData.teeth}
    onChange={handleChange}
  />
</label>
*/}

<h2>Investigations</h2>
<label>
  CBC (Complete Blood Count):
  <input
    type="text"
    name="investigations.CBC"
    value={formData.investigations.CBC}
    onChange={handleChange}
  />
</label>
<label>
  Blood Glucose (2 Hours After 75g Glucose):
  <input
    type="text"
    name="investigations.Blood_Glucose_after_2_hours"
    value={formData.investigations.Blood_Glucose_after_2_hours}
    onChange={handleChange}
  />
</label>
<label>
  TSH (Thyroid-Stimulating Hormone):
  <input
    type="text"
    name="investigations.TSH"
    value={formData.investigations.TSH}
    onChange={handleChange}
  />
</label>
<label>
  FT4 (Free Thyroxine):
  <input
    type="text"
    name="investigations.FT4"
    value={formData.investigations.FT4}
    onChange={handleChange}
  />
</label>
<label>
  Urine RME + CS (Routine Microscopic and Culture Sensitivity):
  <input
    type="text"
    name="investigations.urine_rme_cs"
    value={formData.investigations.urine_rme_cs}
    onChange={handleChange}
  />
</label>
<label>
  VDRL (Syphilis Test):
  <input
    type="text"
    name="investigations.VDRL"
    value={formData.investigations.VDRL}
    onChange={handleChange}
  />
</label>
<label>
  HbsAg / Anti-HCV (Hepatitis B & C):
  <input
    type="text"
    name="investigations.HbsAg_anti_hcv"
    value={formData.investigations.HbsAg_anti_hcv}
    onChange={handleChange}
  />
</label>
<label>
  HIV Test:
  <input
    type="text"
    name="investigations.HIV"
    value={formData.investigations.HIV}
    onChange={handleChange}
  />
</label>
<label>
  USG (Ultrasound):
  <input
    type="text"
    name="investigations.Ultrasonogram"
    value={formData.investigations.Ultrasonogram}
    onChange={handleChange}
  />
</label>
{/*
<label>
  Others (Specify):
  <textarea
    name="others"
    value={formData.others}
    onChange={handleChange}
  />
</label>
*/}
<h2>Antenatal Examination</h2>
<label>
  Date:
  <input
    type="date"
    name="antenatal_examination.Date"
    value={formData.antenatal_examination.Date}
    onChange={handleChange}
  />
</label>
<label>
  Gestation (Weeks):
  <input
    type="number"
    name="antenatal_examination.Gestation_weeks"
    value={formData.antenatal_examination.Gestation_weeks}
    onChange={handleChange}
  />
</label>
<label>
  Weight:
  <input
    type="text"
    name="antenatal_examination.weight"
    value={formData.antenatal_examination.weight}
    onChange={handleChange}
  />
</label>
<label>
  Pulse:
  <input
    type="text"
    name="antenatal_examination.pulse"
    value={formData.antenatal_examination.pulse}
    onChange={handleChange}
  />
</label>
<label>
  Blood Pressure (BP):
  <input
    type="text"
    name="antenatal_examination.blood_pressure"
    value={formData.antenatal_examination.blood_pressure}
    onChange={handleChange}
  />
</label>
<label>
  Anemia:
  <input
    type="text"
    name="antenatal_examination.anemia"
    value={formData.antenatal_examination.anemia}
    onChange={handleChange}
  />
</label>
<label>
  Edema:
  <input
    type="text"
    name="antenatal_examination.Edema"
    value={formData.antenatal_examination.Edema}
    onChange={handleChange}
  />
</label>
<label>
  SFH (Symphysis-Fundal Height in CM):
  <input
    type="text"
    name="antenatal_examination.SFH_cm"
    value={formData.antenatal_examination.SFH_cm}
    onChange={handleChange}
  />
</label>
<label>
  Lie / Presentation:
  <input
    type="text"
    name="antenatal_examination.Lie_presentation"
    value={formData.antenatal_examination.Lie_presentation}
    onChange={handleChange}
  />
</label>
<label>
  Liquor Volume:
  <input
    type="text"
    name="antenatal_examination.Liquor_volume"
    value={formData.antenatal_examination.Liquor_volume}
    onChange={handleChange}
  />
</label>
<label>
  Fetal Movement:
  <input
    type="text"
    name="antenatal_examination.Fetal_movement"
    value={formData.antenatal_examination.Fetal_movement}
    onChange={handleChange}
  />
</label>
<label>
  Fetal Heart Rate:
  <input
    type="text"
    name="antenatal_examination.Fetal_heart_rate"
    value={formData.antenatal_examination.Fetal_heart_rate}
    onChange={handleChange}
  />
</label>
<label>
  Urine Protein:
  <input
    type="text"
    name="antenatal_examination.Urine_protein"
    value={formData.antenatal_examination.Urine_protein}
    onChange={handleChange}
  />
</label>
<label>
  Urine Glucose:
  <input
    type="text"
    name="antenatal_examination.Urine_glucose"
    value={formData.antenatal_examination.Urine_glucose}
    onChange={handleChange}
  />
</label>
<h2>Postnatal Information</h2>
<label>
  Date of Delivery:
  <input
    type="date"
    name="postnatal_info.delivery_date"
    value={formData.postnatal_info.delivery_date}
    onChange={handleChange}
  />
</label>
<label>
  Baby Status (Alive/Stillbirth/Neonatal Death):
  <select
    name="postnatal_info.babyStatus"
    value={formData.postnatal_info.babyStatus}
    onChange={handleChange}
  >
    <option value="">Select Status</option>
    <option value="alive">Alive</option>
    <option value="stillbirth">Stillbirth</option>
    <option value="neonatalDeath">Neonatal Death</option>
  </select>
</label>
<label>
  Place of Delivery (Home/Hospital):
  <select
    name="postnatal_info.Place_of_delivery"
    value={formData.postnatal_info.Place_of_delivery}
    onChange={handleChange}
  >
    <option value="">Select Place</option>
    <option value="home">Home</option>
    <option value="hospital">Hospital</option>
  </select>
</label>
<label>
  Birth Weight:
  <input
    type="text"
    name="postnatal_info.baby_weight"
    value={formData.postnatal_info.baby_weight}
    onChange={handleChange}
  />
</label>
<label>
  Mode of Delivery (Normal/Breech/Forceps/Ventouse/Cesarean):
  <select
    name="postnatal_info.Mode_of_delivery"
    value={formData.postnatal_info.Mode_of_delivery}
    onChange={handleChange}
  >
    <option value="">Select Mode</option>
    <option value="normal">Normal</option>
    <option value="breech">Breech</option>
    <option value="forceps">Forceps</option>
    <option value="ventouse">Ventouse</option>
    <option value="cesarean">Cesarean</option>
  </select>
</label>
<label>
  Post-Partum Complications (If Any):
  <textarea
    name="postnatal_info.Post_Partum_Complications"
    value={formData.postnatal_info.Post_Partum_Complications}
    onChange={handleChange}
  />
</label>

        {/* Add other sections similarly */}

        {/* Submit Button */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AntenatalCardFormIntake;
