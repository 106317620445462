import React, { useState } from "react";
import AntenatalCardFormIntake from "./AntenatalCardFormIntake";
import AntenatalCardFormVisualization from "./AntenatalCardFormVisualization";
const AntenatalCardForm = () => {
    const [activeTab, setActiveTab] = useState("tab1");
  
    return (
      <div>
        {/* Tabs */}
        <div style={styles.tabContainer}>
          <button
            style={{
              ...styles.tab,
              ...(activeTab === "tab1" ? styles.activeTab : {}),
            }}
            onClick={() => setActiveTab("tab1")}
          >
            View ANC Form Data 
          </button>
          <button
            style={{
              ...styles.tab,
              ...(activeTab === "tab2" ? styles.activeTab : {}),
            }}
            onClick={() => setActiveTab("tab2")}
          >
            ANC Form Data Intake
          </button>
        </div>
  
        {/* Tab Content */}
        <div style={styles.content}>
          {activeTab === "tab1" && <AntenatalCardFormVisualization />}
          {activeTab === "tab2" && <AntenatalCardFormIntake />}
        </div>
      </div>
    );
  };

// Styles
const styles = {
    app: {
      background: "#000", // Black background
      color: "#fff", // White text for visibility
      minHeight: "100vh",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
    },
    tabContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    tab: {
      padding: "10px 20px",
      cursor: "pointer",
      background: "#333", // Dark gray background
      color: "#fff", // White text
      border: "1px solid #555", // Border matches dark theme
      borderRadius: "5px",
      margin: "0 5px",
    },
    activeTab: {
      background: "#007BFF", // Blue background for active tab
      color: "#fff",
      border: "1px solid #007BFF",
    },
    content: {
      padding: "20px",
      border: "1px solid #555",
      borderRadius: "5px",
      background: "#222", // Slightly lighter black for contrast
    },
  };
export default AntenatalCardForm;  